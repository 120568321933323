@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,700;1,400&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'EB Garamond', serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* index.css */

.markdown h1 {
  font-size: 2em;
  margin-bottom: 0.5em;
}

.markdown h2 {
  font-size: 1.5em;
  margin-bottom: 0.5em;
}

.markdown p {
  margin-bottom: 1em;
}

.markdown ul {
  list-style-type: disc;
  margin-left: 1.5em;
  margin-bottom: 1em;
}

.markdown li {
  margin-bottom: 0.5em;
}

.markdown strong {
  font-weight: bold;
}

/* More specific targeting using !important to ensure it takes precedence */
.tiptap.ProseMirror-focused {
  outline: none !important;
}
